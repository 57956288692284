$theme-colors: (
  "primary": #008075,
);

/* import bootstrap to set customizations from above */
@import "~bootstrap/scss/bootstrap";

.sh-display-linebreaks {
  white-space: pre-line;
}
